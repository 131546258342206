const env = process.env;
const API = `${env.VUE_APP_BACKEND_URL}/`

const {VUE_APP_BACKEND_URL} = process.env
// axios.post(`${VUE_APP_BACKEND_URL}`,data,Headers)


// switch (env.NODE_ENV) {
//   case 'development':
//     console.log(development);
//     break;
//   case 'production':
//     console.log(production);
//     break;

//   default:
//     break;
// }

// if (env.VUE_APP_API === '13.213.49.28') {
//   API = `http://${env.VUE_APP_API}:${env.VUE_APP_PORT}/`;
// } else {
//   API = `https://asset.niets.or.th:4433/`;
// }


export default API;
//serve http://10.99.61.21:3019/
//local http://10.0.0.205:5008/


// VUE_APP_API=13.213.49.28
// VUE_APP_PORT=4008
// VUE_APP_BASE_URL=/


// VUE_APP_API=asset.niets.or.th
// VUE_APP_PORT=4433
// VUE_APP_BASE_URL=/